/*
* Theme: BeeBack Client Master
* Author: BeeBack, LLC.
* Author URL: http://beeback.io
*/

/** Import **/
@import "assets/variables";
@import "assets/mixins";
@import "base";
@import "helpers";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600');



/** Overwrite Bootstrap **/
.form-group {
    margin: .5rem 0;
    @extend %clearfix;
}

select[multiple] {
    overflow: scroll;
}

.badge {
    padding: .5em .75em;
}

.table-hover tbody tr:hover {
    background-color: $bodyColorLight;
}

.progress-bar {
    height: 20px;
    line-height: 20px;

    &.progress-bar-striped {
        @include linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    }
}

// Modals
.modal-backdrop {
    background: rgba(0, 0, 0, 0.85);

    &.show {
        @include opacity(1);
    }
}

.modal {
    .modal-content {
        padding: $padding-xl;
        @include border-radius(6px);

        button.close {
            font-weight: $fontLight;
            color: $textColorLight;
        }

        .modal-header {
            border: none;
            padding: 0;

            .modal-title {
                font-weight: $fontLight;
                color: $textColorDark;
            }
        }

        .modal-body {
            border: none;
            padding: 0;
        }

        .modal-footer {
            border: none;
            padding: 0;

            &.block-button {
                margin: $padding-xl (-$padding-xl) (-$padding-xl);

                button {
                    width: 100%;
                    display: block;
                    text-align: center;
                    @include border-radius(0 0 4px 4px);
                }
            }
        }
    }
}

/** Loader **/
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .loader-section {
        position: fixed;
        top: 0;
        width: 51%;
        height: 100%;
        background-color: $yellowAlert;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: multiply;
        z-index: 1000;
        @include transform(translateX(0));

        &.section-left {
            left: 0;
        }

        &.section-right {
            right: 0;
        }
    }
}

#loader {
    display: block;
    z-index: 10001;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;

    .bee {
        position: relative;
        margin: auto;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        width: 50px;
        height: 50px;
        @include animation('to-fly 0.4s infinite');

        .bee-body {
            position: relative;
            width: 47px;
            height: 26px;
            border: 4px solid #fbc02d;
            background: #ffeb3b;
            @include border-radius(20px);
            -webkit-perspective: 2500px;
            perspective: 2500px;
            z-index: 99;

            &:before, &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
            }
            &:before {
                right: 100%;
                top: 3px;
                border-top: 6px solid transparent;
                border-right: 13px solid #fbc02d;
                border-bottom: 6px solid transparent;
            }

            &:after {
                right: 97%;
                top: 6px;
                border-top: 3px solid transparent;
                border-right: 6px solid #ffeb3b;
                border-bottom: 3px solid transparent;
            }

            .line {
                position: relative;
                left: 10px;
                height: 18px;
                width: 18px;
                background: #fbc02d;
            }
        }

        .wing-right, .wing-left {
            position: absolute;
            top: -18px;
            left: 12px;
            width: 24px;
            height: 24px;
            background: #ffeb3b;
            border: 4px solid #fbc02d;
            border-radius: 100%;
        }

        .wing-right {
            left: 15px;
            @include transform(skew(-20deg));
            @include animation('wing-beat-right 0.25s infinite');
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
            z-index: 9;
        }

        .wing-left {
            @include transform(skew(20deg));
            @include animation('wing-beat-left 0.25s infinite');
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
            z-index: 999;
        }

        .bee-path {
            display: inline-block;
            position: relative;
            text-align: left;
            top: -28px;
            left: -90px;
            width: 140px;
            @include animation('pollen 0.14s infinite');

            .pollen {
                display: inline-block;
                margin: 0;
                width: 3px;
                height: 3px;
                background: #fbc02d;
                border-radius: 100%;
            }
        }
    }
}

@include keyframes(to-fly) {
    50% { @include transform(translateY(-3px)); }
    100% { @include transform(translateY(0px)); }
}

@include keyframes(wing-beat-right) {
    50% { @include transform(rotateX(60deg) skew(-20deg) rotateZ(25deg)); }
    100% { @include transform(rotateX(0) skew(-20deg)); }
}

@include keyframes(wing-beat-left) {
    50% { @include transform(rotateX(-65deg) skew(20deg) rotateZ(-10deg)); }
    100% { @include transform(rotateX(0) skew(20deg)); }
}

@include keyframes(pollen) {
    0% { left: -90px; }
    100% { left: -95px; }
}

// Loaded
.loaded #loader-wrapper .loader-section.section-left {
    @include transform(translateY(-100%));
    @include transition(all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000));
}

.loaded #loader-wrapper .loader-section.section-right {
    @include transform(translateY(100%));
    @include transition(all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000));
}

.loaded #loader {
    @include opacity(0);
    transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
    visibility: hidden;
    @include transform(translateX(-100%));
    @include transition(all 0.3s 1s ease-out);
}

/** Login Page **/
#loginPage {
    position: relative;

    .login-outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        table-layout: fixed;

        .login-inner-container {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            float: none;

            .login {
                width: 94%;
                max-width: 1000px;
                background: white;
                margin: $padding auto;
                overflow: hidden;
                @include border-radius(4px);
                @include box-shadow(0 10px 56px 3px rgba(0,0,0,0.1));

                .logo {
                    margin-bottom: 3*$padding-lg;
                    @include opacity(.62);

                    img {
                        height: $logoSize;
                        max-width: 100%;
                    }
                }

                .login-form {
                    display: block;
                    padding: 3*$padding-xl 2*$padding-lg;

                    &.small-gutter {
                        padding: 1.5*$padding-xl 2*$padding-lg;
                    }

                    h5 {
                        margin-top: $padding;
                        font-size: $fontSize;
                    }

                    .form-group {
                        padding: 0;
                        margin-bottom: $padding;
                        position: relative;
                        display: block;

                        label {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: $navSizeMobile;
                            height: $navSizeMobile;
                            line-height: $navSizeMobile;
                            text-align: center;
                            border-right: 1px solid $borderColor;
                            color: $textColorLight;
                            @include transition(all .1s ease);
                        }

                        input {
                            width: 100%;
                            border: 1px solid $borderColor;
                            height: $navSizeMobile;
                            line-height: $navSizeMobile;
                            padding: 0 $padding 0 ($navSizeMobile + $padding);
                            color: $textColor;
                            @include transition(all .1s ease);

                            &:focus {
                                outline: none;
                                border-color: $textColor;
                                @include box-shadow(none);
                                @include transition(all .1s ease);

                                ~ label {
                                    border-color: $textColor;
                                    color: $textColorDark;
                                    @include transition(all .1s ease);
                                }
                            }
                        }

                        .select-box {
                            font-size: $fontSize;
                            position: relative;
                            height: $navSizeMobile;
                            line-height: $navSizeMobile;
                            border: 1px solid $borderColor;

                            select {
                                -webkit-appearance:none;
                                -moz-appearance:none;
                                appearance:none;
                                padding-right: 40px;
                                padding-left: ($navSizeMobile + $padding);
                                border: none;
                                background: transparent;
                                color: $textColor;

                                option {
                                    &:disabled {
                                        color: $textColorLight;
                                    }
                                }
                            }

                            &:after {
                                position: absolute;
                                right: 0;
                                top: 0;
                                content: '\21F5';
                                pointer-events: none;
                                padding: 0 $padding;
                                color: $textColorLight;
                                height: $navSizeMobile;
                                line-height: $navSizeMobile;
                            }
                        }
                    }

                    .login-button {
                        margin-top: $padding-lg;

                        .forgot-password {
                            display: inline-block;
                            font-weight: $fontNormal;
                            color: $textColorLight;
                            padding: $padding-sm 0;

                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }

                    .login-facebook {
                        text-align: center;
                        margin-top: $padding-xl;
                    }
                }

                .login-info {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    padding: 2*$padding-xl;
                    background: $secondaryColor;
                    background: url('../images/unsplash/coffee.jpg') no-repeat center center;
                    background-size: cover;

                    .register-info {
                        display: block;
                        text-align: center;
                        padding: $padding;
                        background: rgba(255,255,255,0.85);
                        @include box-shadow(0 1px 10px rgba(0,0,0,0.1));
                    }
                }



                @media screen and (max-width: $grid-md){
                    .login-form {
                        padding: $padding-xl;
                    }

                    .logo {
                        margin-bottom: $padding-xl;
                    }
                }
            }
        }
    }
}

.page-container {
    &.toggled {

        @media screen and (min-width: $grid-md){
            main {
                margin-left: 0;
                @include transition(all .25s ease);
            }
        }

        @media screen and (min-width: $grid-md) and (max-width: ($grid-xl - 1px)){
            .sidebar {
                margin-left: 0;
                overflow-x: hidden;
                @include transition(all .25s ease);
            }
        }
    }
}

/** Container & Definitions **/
main {
    position: relative;
    min-height: 100vh;
    padding-top: $navSize;
    background: $bodyColor;
    @include transition(all .25s ease);

    @media screen and (max-width: ($grid-md) - 1px){
        margin-left: 0;
        padding-top: $navSizeMobile;
    }

    @media screen and (max-width: ($grid-lg) - 1px){
        margin-left: 0;
    }

    @media screen and (min-width: $grid-xl){
        margin-left: $sidebarSize - $padding-lg;
    }
}

section {
    padding: $padding-sm $padding-lg;

    @media screen and (max-width: ($grid-md - 1px)){
        padding: $padding-sm;
    }

    @media screen and (min-width: $grid-lg){
        padding: $padding-sm 2*$padding-sm $padding-sm $padding;
    }
}

/** Sidebar **/
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    background: white;
    @include box-shadow(1px -25px 10px 0 $sidebarShadow);

    @media screen and (min-width: $grid-md){
        width: $sidebarSize - 2*$padding-lg;
    }

    @media screen and (min-width: $grid-md) and (max-width: ($grid-xl - 1px)){
        position: fixed;
        z-index: 10;
        padding-top: $navSize;
        margin-left: -($sidebarSize - $padding-xl);
        @include transition(all .25s ease);

        .logo {
            display: none;
        }

        nav {
            bottom: $navSize !important;
        }
    }

    @media screen and (min-width: $grid-lg){
        width: $sidebarSize - $padding-xl;
    }

    .logo {
        margin-bottom: 3*$padding;
        height: $navSize;

        a {
            display: block;
            position: relative;
            height: $navSize;
            line-height: $navSize;
            padding-left: $navSize + $padding-lg;
            background: $primaryColor;
            color: white;

            .icon {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: $navSize;
                width: $navSize;
                text-align: center;
                background: darken($primaryColor, 5%);

                img {
                    width: 34px;
                    max-width: 100%;
                }
            }
        }
    }

    .merchant-info {
        padding: 3*$padding 0;
        padding: 2*$padding;

        .merchant-name {
            margin-bottom: .5*$padding;
            color: $textColorDark;
        }

        .merchant-location {
            font-weight: $fontLight;

            span {
                color: $primaryColor;
                font-weight: $fontBold;
            }
        }

        @media screen and (min-width: $grid-md){
            .merchant-name {
                font-size: 2em;
            }
        }
    }

    nav {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 2*$padding;

        ul {
            padding: 0;
            margin: 2*$padding 0 0;

            li {
                list-style-type: none;

                a {
                    display: block;
                    color: $textColor;
                    position: relative;
                    font-weight: $fontNormal;
                    line-height: 2.75*$padding;
                    //border-bottom: 1px dotted $bodyColor;

                    i.nav-arrow-icon {
                        position: absolute;
                        right: 25%;
                        color: lighten($textColorLight, 10%);
                        line-height: 2.75*$padding;
                        @include opacity(0);
                        @include transition(all .25s ease);
                    }

                    &:hover {
                        color: $textColorDark;
                        font-weight: $fontBold;

                        i.nav-arrow-icon {
                            right: 0;
                            @include opacity(1);
                            @include transition(all .25s ease);
                        }
                    }

                    &.active {
                        color: $primaryColor;
                        font-weight: $fontBold;

                        i {
                            right: 0;
                            @include opacity(1);
                            @include transition(all .25s ease);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: ($grid-md - 1px)){
        width: 100%;
        display: block;
        z-index: 2;
        border: none;
        top: $navSizeMobile;
        height: 0;
        overflow: hidden;
        @include box-shadow(none);
        @include transition(all .25s ease);

        &.active {
            //display: block;
            min-height: 100%;
            @include transition(all .25s ease);
        }

        .logo {
            display: none;
        }

        .merchant-info {
            padding-bottom: 0;

            .merchant-name {
                font-size: 1.5*$fontSize;
            }
        }

        nav {
            position: relative;
            padding-top: 0;
        }
    }
}

/** Main Content **/
// topbar
.topbar {
    position: absolute;
    z-index: 20;
    right: 0;
    top: 0;
    left: 0;
    text-align: right;
    background: white;
    height: $navSize;
    line-height: $navSize;
    @include box-shadow(25px 1px 10px 0 $sidebarShadow);

    @media screen and (min-width: $grid-lg){
        left: -$padding-sm;
    }

    ul {
        padding: 0 0 0 $padding-lg;
        margin: 0;
        height: $navSize;
        line-height: $navSize;
        @extend %clearfix;

        li {
            list-style-type: none;
            float: left;
            display: block;

            > .dropdown-toggle {
                min-width: $navSize;

                &:after {
                    display: none;
                }
            }

            #mobileMenu {
                display: block;
                width: $navSizeMobile;
                height: $navSizeMobile;
                line-height: $navSizeMobile;
                padding: 0;
                text-align: center;

                .closed {
                    display: block;
                }

                .open {
                    display: none;
                }

                &.active {
                    color: $primaryColor;

                    .closed {
                        display: none;
                    }

                    .open {
                        display: block;
                    }
                }
            }

            .dropdown-menu {
                height: auto;
                left: auto;
                border: none;
                padding: 0;
                margin: 0;
                @include border-radius(0);
                @include box-shadow($boxShadow);

                li {
                    display: block;
                    width: 100%;
                    padding: 0;

                    a {
                        display: block;
                        font-size: $fontSize;
                        color: $textColor;
                        line-height: $lineHeight;
                        padding: $padding-sm $padding;
                        border-bottom: 1px solid $bodyColor;

                        i {
                            margin-right: 10px;
                        }

                        &:hover {
                            background: $bodyColor;
                        }
                    }

                    &:last-child {
                        a {
                            border: none;
                        }
                    }
                }
            }

            .btn {
                position: relative;
                border: none;
                background: transparent;
                font-size: $fontSize;
                height: $navSize;
                line-height: $navSize;
                padding: 0 $padding-sm;
                font-size: 1.25*$fontSize;
                min-width: .65*$navSize;
                @include box-shadow(none);

                &:hover {
                    color: $textColorDark;
                }

                i {
                    position: relative;
                }

                @media screen and (min-width: $grid-md){
                    padding: 0 $padding;
                }

                @media screen and (max-width: ($grid-md - 1px)){
                    height: $navSizeMobile;
                    line-height: $navSizeMobile;
                }
            }

            &.notification {
                font-size: $fontSize;

                .btn {

                    .notification-count {
                        display: block;
                        font-family: $fontPrimary;
                        font-size: 10px;
                        height: .5*$logoSize;
                        line-height: .5*$logoSize;
                        text-align: center;
                        padding: 0 $padding-xs;
                        min-width: .5*$logoSize;
                        position: absolute;
                        right: -$padding;
                        top: -$padding-sm;
                        background: $primaryColor;
                        color: white;
                        text-shadow: 0 1px 0 rgba(0,0,0,0.2);
                        @include border-radius(.25*$logoSize);
                    }
                }

                .dropdown-menu {
                    max-height: 500px;
                    width: 400px;
                    overflow: auto;

                    a {
                        display: block;
                        position: relative;
                        padding: $padding $padding $padding ($navSizeMobile + 2*$padding);

                        .avatar {
                            position: absolute;
                            left: $padding;
                            top: $padding;
                            width: $navSizeMobile;
                            height: $navSizeMobile;
                            overflow: hidden;
                            @include border-radius(50%);

                            img {
                                width: 100%;
                            }
                        }

                        .name, .message, .time {
                            display: block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }

                        .message {
                            color: $textColorLight;
                            font-weight: $fontNormal;
                        }

                        .time {
                            position: absolute;
                            right: $padding;
                            top: $padding;
                            color: $textColorLight;
                            font-size: $fontSizeSmall;
                        }

                        &.all-notifications {
                            display: block;
                            border: none;
                            text-align: center;
                            font-weight: $fontBold;
                            padding: $padding;

                            i {
                                display: inline-block;
                                margin-left: $padding-xs;
                            }
                        }
                    }
                }

                &.show {
                    .dropdown-toggle {
                        //background: $bodyColorLight;
                        color: $primaryColor;
                    }
                }
            }

            &.user-account {
                float: right;
                font-size: $fontSize;
                line-height: 1.4em;

                .dropdown-toggle {
                    border: none;
                    background: transparent;
                    font-size: $fontSize;
                    height: $navSize;
                    line-height: 1.4em;
                    position: relative;
                    padding: 0;
                    padding-left: $padding;
                    padding-right: $navSize + $padding;
                    text-align: right;
                    display: table;
                    @include box-shadow(none);

                    .avatar {
                        height: $navSize;
                        width: $navSize;
                        overflow: hidden;
                        display: block;
                        vertical-align: middle;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background: $blueAlert;

                        img {
                            max-width: 100%;
                        }
                    }

                    .user-content {
                        display: table-cell;
                        vertical-align: middle;

                        .user-name {
                            display: block;
                            font-size: 1.25*$fontSize;
                            color: $textColorDark;
                        }

                        .user-plan {
                            display: block;
                            font-size: $fontSizeSmall;
                            color: $textColor;
                        }
                    }

                    &:hover {
                        background: $bodyColorLight;
                    }
                }
            }

            a {
                @include border-radius(0);

                &:hover {
                    background: $bodyColorLight;
                }
            }

            &.show {
                .dropdown-toggle {
                    //background: $bodyColorLight;
                }
            }
        }
    }

    @media screen and (max-width: ($grid-md - 1px)){
        position: fixed;
        top: 0;
        left: 0;
        height: $navSizeMobile;
        line-height: $navSizeMobile;
        z-index: 30;
        @include box-shadow($boxShadow);

        ul {
            height: $navSizeMobile;
            line-height: $navSizeMobile;
            padding: 0;

            .dropdown-toggle {
                height: $navSizeMobile !important;
                line-height: $navSizeMobile !important;
            }

            li.user-account {
                .dropdown-toggle {
                    padding-left: 0;
                    padding-right: $navSizeMobile + $padding-sm;

                    .avatar {
                        width: $navSizeMobile;
                        height: $navSizeMobile;
                        overflow: hidden;
                    }

                    .user-content {
                        .user-name {
                            font-size: $fontSize;
                        }

                        .user-plan {
                            display: none;
                        }
                    }
                }
            }

            li.notification {
                ul.dropdown-menu {
                    position: fixed;
                    left: 0;
                    width: 100%;
                    top: $navSizeMobile + 1px;
                }
            }
        }
    }
}

// Title Box
.title-box {
    h2 {
        margin: 0;
        font-size: $fontSizeLarge;
        font-weight: $fontBold;

        .pull-right {
            display: inline-block;
        }
    }
}


// Box
.box {
    display: block;
    background: white;
    padding: $padding;
    position: relative;
    margin: $padding-sm 0;
    @include box-shadow(0 5px 15px rgba(0,0,0,.05));
    @include transition(all .25s ease);

    &.no-shadow {
        @include box-shadow(none);
    }

    &:hover {
        @include box-shadow($boxShadow);
        @include transition(all .25s ease);
    }

    &.no-padding {
        padding: 0;
    }

    &.no-tb-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.box-fullscreen {
        height: 100%;
        left: 0;
        margin: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        @include transition(all .25s ease);
    }

    .box-header {
        display: block;
        position: relative;
        margin-left: -$padding;
        margin-right: -$padding;
        margin-top: -$padding;
        margin-bottom: $padding;
        height: $boxHeaderSize;
        border-bottom: 1px dashed $borderColor;

        h1, h2, h3, h4 {
            display: inline-block;
            font-size: $fontSize;
            font-weight: $fontBold;
            margin: 0;
            padding: 0 $padding;
            line-height: $boxHeaderSize;
            border-right: 1px dashed $borderColor;
        }

        .box-header-right {
            display: inline-block;
            float: right;

            ul {
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    float: right;
                    cursor: pointer;
                    vertical-align: middle;

                    > i {
                        display: block;
                        width: $boxHeaderSize;
                        height: $boxHeaderSize;
                        line-height: $boxHeaderSize;
                        border-left: 1px dashed $borderColor;
                        text-align: center;
                    }
                }

                .dropdown {
                    position: relative;

                    &.show {
                        color: $primaryColor;
                    }

                    .dropdown-menu {
                        left: auto;
                        right: -$padding-xs;
                        border: none;
                        padding: 0;
                        margin: 0;
                        @include border-radius(0);
                        @include box-shadow(0 1px 2px 1px rgba(0,0,0,0.15));

                        li {
                            display: block;
                            width: 100%;
                            padding: 0;

                            a {
                                display: block;
                                font-size: .9*$fontSize;
                                color: $textColor;
                                padding: $padding-sm $padding;
                                border-bottom: 1px solid $bodyColor;

                                i {
                                    margin-right: 10px;
                                }

                                &:hover {
                                    background: $bodyColor;
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-tabs {
            border: none;

            .nav-item {
                border: none;

                .nav-link {
                    border: none;
                    border-right: 1px dashed $borderColor;
                    line-height: $boxHeaderSize;
                    padding: 0 $padding;
                    font-weight: $fontNormal;
                    @include border-radius(0);

                    &:hover {
                        color: $textColorDark;
                    }

                    &.active {
                        border: none;
                        font-weight: $fontBold;
                        color: $primaryColor;
                        border-right: 1px dashed $borderColor;
                        background: white;
                    }

                    i {
                        display: inline-block;
                        margin-right: $padding-sm;
                    }
                }
            }
        }
    }

    .box-header-simple {
        display: block;
        position: relative;
        margin-left: -$padding;
        margin-right: -$padding;
        margin-top: -$padding;
        height: $boxHeaderSize;
        line-height: $boxHeaderSize;
        border-bottom: 1px dashed $borderColor;

        h1, h2, h3, h4 {
            display: inline-block;
            font-size: $fontSize;
            font-weight: $fontBold;
            margin: 0;
            letter-spacing: .025em;
            padding: 0 $padding;

            i {
                display: inline-block;
                margin-right: $padding-xs;
            }
        }

        p {
            display: inline-block;
            margin: 0;
            font-weight: $fontNormal;
            letter-spacing: .025em;
            padding: 0 $padding;

            a {
                display: inline-block;
                margin: 0;
                font-weight: $fontNormal;
                letter-spacing: .025em;
            }
        }

        .box-header-right {
            display: inline-block;
            float: right;

            ul {
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    float: right;
                    vertical-align: middle;
                    height: $boxHeaderSize;
                    line-height: $boxHeaderSize;

                    a {
                        display: block;
                        padding: 0 $padding;
                        font-weight: $fontNormal;
                        line-height: $boxHeaderSize;

                        i {
                            display: inline-block;
                            margin-right: $padding-xs;
                        }

                        &.active {
                            background: $bodyColorLight;
                            font-weight: $fontBold;
                        }
                    }
                }
            }
        }
    }

    .box-content {
        padding-top: $padding;
    }

    @media screen and (min-width: $grid-lg) {
        &.flush-button {
            padding: 0;

            .flush-button-content {
                padding: 13px $padding;
                display: inline-block;
                max-width: 70%;
            }

            .button {
                height: 100%;
                float: right;
                @include border-radius(0);

                i {
                    height: 100%;
                    margin-top: 1px;
                }
            }
        }
    }
}

// same height
.auto-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;

    > [class*='col-'], > .col {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;

        .box {
            height: 100%;
        }
    }
}

.auto-center {
    text-align: center;
    width: 100%;
    @include vertical-align(relative);
}

/** Input Forms **/
select, select.form-control, text-area, input:not([type=checkbox]):not([type=radio]):not([type="file"]) {
    width: 100%;
    border: 1px solid $borderColor;
    height: $inputSize;
    line-height: $inputSize;
    padding: 0 $padding;
    color: $textColor;
    font-family: $fontPrimary;
    @include border-radius(0);

    &:focus {
        outline: none;
        border-color: darken($borderColor, 15%);
        @include box-shadow(none);
    }

    &.input-sm {
        height: $inputSizeSm;
        line-height: $inputSizeSm;
    }
}

.input-group {
    .input-group-addon {
        border: 1px solid $borderColor;
        margin-right: -1px;
        height: $inputSize;
        line-height: $inputSize;
        padding: 0 $padding;
        color: $textColor;
        font-family: $fontPrimary;
        @include border-radius(0);
    }
}

.form-group {
    margin-bottom: $padding;

    label {
        margin-bottom: 0;
    }

    .help-block {
        font-size: $fontSizeSmall;
        color: $textColorLight;
        margin: 0;
    }
}

.checkbox {
    margin-bottom: $padding;

    input[type="checkbox"] {
        display: none;

        &:checked+label {
            &:before {
                border-color: $tirtiaryColor;
                background: $tirtiaryColor;
            }

            &:after {
                @include opacity(1);
            }
        }

        &:disabled+label {
            @include opacity(.6);
        }
    }

    label {
        position: relative;
        padding-left: $padding-lg + $padding-xs;
        cursor: pointer;
        @extend %no-select;

        &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ccc;
            background: #ffffff;
            @include border-radius(3px);
            @include transition(all .4s ease);
        }

        &:after {
            content: "\f00c";
            font-family: 'FontAwesome';
            font-size: 12px;
            line-height: 16px;
            opacity: 0;
            position: absolute;
            top: 3px;
            left: 3px;
            color: white;
            @include opacity(0);
            @include transition(all .4s ease);
        }
    }
}

.radio {
    margin-bottom: $padding-xs;

    input[type="radio"] {
        display: none;

        &:checked+label {
            &:before {
                border-color: $tirtiaryColor;
                background: $tirtiaryColor;
            }

            &:after {
                @include opacity(1);
            }
        }

        &:disabled+label {
            @include opacity(.6);
        }
    }

    label {
        position: relative;
        padding-left: $padding-xl;
        cursor: pointer;
        @extend %no-select;

        &:before {
            content: "";
            position: absolute;
            top: 3px;
            left: 0;
            width: 19px;
            height: 19px;
            border: 1px solid $borderColor;
            background: #ffffff;
            @include border-radius(50%);
            @include transition(all .4s ease);
        }

        &:after {
            /*content: "\00b7";
            font-size: 14px;
            line-height: 18px;*/
            content: " ";
            width: 7px;
            height: 7px;
            background: white;
            position: absolute;
            top: 9px;
            left: 6px;
            color: white;
            @include border-radius(50%);
            @include transition(all .4s ease);
        }
    }
}

.select-order-status {
    height: $inputSize;
    position: relative;
    padding: 0 !important;

    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        padding-right: 40px;
        border: none;
        background: transparent;
        color: $textColor;

        option {
            &:disabled {
                color: $textColorLight;
            }
        }
    }

    &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '\21F5';
        pointer-events: none;
        padding: 0 $padding;
        height: $inputSize;
        line-height: $inputSize;
        color: $textColorLight;
        //font-size: $fontSizeLarge;
    }
}

.select-dropdown {
    font-size: $fontSize;
    height: 26px;
    position: relative;
    padding: 0 !important;

    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        padding-right: 40px;
        border: none;
        background: transparent;
        color: $textColor;
        height: 26px;
        line-height: 26px;

        option {
            &:disabled {
                color: $textColorLight;
            }
        }
    }

    &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '\21F5';
        pointer-events: none;
        padding: 0 $padding;
        color: $textColorLight;
        height: 26px;
        line-height: 26px;
    }
}

.file-select {
    border: 1px solid $borderColor;
    cursor: pointer;
    position: relative;
    font-size: $fontSizeSmall;
    @include transition(.2s ease-in-out);
    @extend %clearfix;

    .file-button {
        background: $borderColor;
        padding: 0 $padding-sm;
        line-height: $inputSize;
        height: $inputSize;
        display: block;
        float: left;
        width: 100px;
        @include transition(.2s ease-in-out);
    }

    .file-name {
        line-height: $inputSize;
        display: block;
        padding: 0 $padding-sm;
        white-space: nowrap;
        float: left;
        width: calc(100% - 100px);
        overflow: hidden;
        text-overflow: ellipsis;
        @include transition(.2s ease-in-out);
    }

    &:hover {
        border-color: darken($borderColor, 15%);
        @include transition(.2s ease-in-out);

        .file-button {
            background: darken($borderColor, 15%);
            @include transition(.2s ease-in-out);
        }
    }

    &.active {
        border-color: $greenAlert;
        @include transition(.2s ease-in-out);

        .file-button {
            background: $greenAlert;
            color: white;
            @include transition(.2s ease-in-out);
        }
    }

    input[type=file] {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        @include opacity(0);
    }
}

.select-icons {
    list-style: none;
    padding: 0;

    li {
        display: inline-block;
        line-height: $inputSize;
        height: $inputSize;
        width: $inputSize;
        position: relative;
        text-align: center;

        i {
            display: block;
            border: 1px solid $borderColor;
            line-height: $inputSize;
            height: $inputSize;
            width: $inputSize;
            text-align: center;
            font-size: $fontSizeLarge;
            @include transition(.2s ease-in-out);
        }

        input[type=radio] {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            @include opacity(0);

            &:checked + i {
                border-color: $greenAlert;
                background: $greenAlert;
                color: white;
                @include transition(.2s ease-in-out);
            }
        }
    }
}

/** Pricing Tables **/
.pricing-table {
	.plan {
		border: 1px solid $borderColor;
		margin: $padding 0;
		position: relative;
		z-index: 1;
        background: white;

		&:hover {
			border-color: darken($borderColor, 5%);
			z-index: 2;
            @include box-shadow(0 1px 5px rgba(0,0,0,0.1));
		}
	}
	.plan-name {
		padding: $padding-xl 0;

		h2 {
			margin: 0;
			font-weight: $fontNormal;

			+ p {
				margin: $padding-sm $padding 0;
			}
		}
	}
	.plan-price {
		background: $bodyColor;
		border-top: 1px solid $borderColor;
		border-bottom: 1px solid $borderColor;
		padding: $padding 0;

		b {
			font-size: 1.5*$fontSize;
			font-weight: $fontNormal;

			b {
				font-size: $fontSize;
				font-weight: $fontBold;
			}
		}
	}

	.plan-details {
		padding: 0 $padding-sm;

		> div {
			background: #fff;
			padding: $padding;
			+ div {
				border-top: 1px solid $borderColor;
			}
		}
	}

	.plan-action {
		background: $bodyColor;
		border-top: 1px solid borderColor;
		padding: $padding;
	}

	.prefered {
        border-color: darken($borderColor, 10%);

        .plan-price {
    		color: $textColor;

    		b {
    			color: $primaryColor;
    		}
        }
	}
}

.pricing-table-2 {
    .plan {
        position: relative;
        padding: $padding-xl;
        background: white;

        .label {
            display: block;
            font-size: $fontSizeSmall;
            text-transform: uppercase;
            height: 28px;
            line-height: 28px;
            min-width: $inputSizeLg;
            padding: 0 $padding-sm;
            text-align: center;
            position: absolute;
            right: $padding;
            top: $padding;
            @include transition(.3s linear);

            i {
                display: inline-block;
                margin-right: $padding-xs;
            }
        }

        h5 {
            line-height: $inputSizeSm;
            margin: 0;
        }

        .price {
            vertical-align: top;
            font-size: 4em;
            line-height: 100%;
            text-align: center;
            font-weight: $fontLight;

            span {
                display: inline-block;
                margin-right: $padding-xs;
                font-size: 60%;
            }
        }

        ul {
            padding: $inputSizeSm 0 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                border-bottom: 1px dotted $borderColor;
                padding: $padding-xs 0 $padding-xs ($inputSizeXs + $padding-sm);

                i {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: $padding-sm;
                    width: $inputSizeXs;
                    height: $inputSizeXs;
                    text-align: center;
                    color: $textColor;
                    @include border-radius(50%);

                    & + span {
                        position: relative;
                        bottom: 2px;
                    }
                }
            }
        }
    }
}

// Invoice
.invoice {
    padding: $padding-xl;

    .invoice-logo {
        width: 150px;

        img {
            max-width: 100%;
        }
    }

    .invoice-address {
        padding: $padding-lg $padding-xl;
        background: $bodyColorLight;
        margin-left: -$padding-xl;
        margin-right: -$padding-xl;

        p {
            margin: 0;
        }
    }

    .invoice-table {
        tfoot {
            font-weight: bold;
            border-top: 2px solid $borderColor;
            text-align: right;
        }
    }

    .invoice-footnote {
        font-style: italic;
        color: $textColorLight;
        font-size: $fontSizeSmall;
    }
}

// Order
.order {
    padding: $padding-xl;

    .order-logo {
        width: 150px;

        img {
            max-width: 100%;
        }
    }

    .order-address {
        padding: $padding-lg $padding-xl;
        background: $bodyColorLight;
        margin-left: -$padding-xl;
        margin-right: -$padding-xl;

        p {
            margin: 0;
        }
    }

    .order-table {
        tfoot {
            font-weight: bold;
            border-top: 2px solid $borderColor;
            text-align: right;
        }

        ol, ul {
            padding-left: $padding-lg;
            margin: 0;
        }
    }

    .order-footnote {
        font-style: italic;
        color: $textColorLight;
        font-size: $fontSizeSmall;
    }
}

// Service modules
.service-modules {
    .service-item {
        margin: 0 auto;
        position: relative;
        padding: $padding $padding $padding 85px;
        margin: $padding-sm 0;
        border: 1px dashed $borderColor;
        height: 100%;

        .icon {
            display: block;
            text-align: center;
            font-size: 2em;
            position:absolute;
            top: $padding;
            left: 0;
            width: 70px;
            color: $textColorLight;
        }

        h2 {
            font-size: $fontSize;
            font-weight: $fontBold;
            margin-bottom: $padding-xs;
        }

        p {
            line-height: 1.47;
            margin: 0;
            font-weight: $fontNormal;
            color: $textColor;
        }
    }
}

// Profile
.profile-sidebar {
    position: relative;

    .avatar {
        display: block;
        margin: 0 auto $padding;
        padding: 0;
        width: 100%;
        height: 180px;
        overflow: hidden;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        img {
            display: none;
        }
    }

    .content {
        h3, h4, .leader {
            margin-bottom: 0;
        }

        .meta {
            color: $textColorLight;
        }
    }
}

.profile-stats {
    position: relative;
    text-align: center;

    h4, p {
        text-align: center;
        margin: 0;
        padding: 0;
    }

    h4 {
        color: $textColorDark;
        font-weight: $fontBold;
    }

    p {
        color: $textColorLight;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.list-icons {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        position: relative;
        line-height: $lineHeight;
        padding: $padding-sm 0 $padding-sm $padding-xl;
        border-bottom: 1px dashed $borderColor;

        i {
            position: absolute;
            left: 0;
            width: 24px;
            line-height: $lineHeight;
        }

        &:last-child {
            border: none;
        }
    }
}

.list-link-icons {
    margin: 0 (-$padding);
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        padding: 0;
        border-bottom: 1px dashed $borderColor;

        a {
            display: block;
            position: relative;
            line-height: $lineHeight;
            padding: $padding-sm $padding $padding-sm ($padding-xl + $padding);

            i {
                position: absolute;
                left: $padding;
                width: 24px;
                line-height: $lineHeight;
            }

            p {
                margin: 0;
            }

            &.active {
                background: $bodyColorLight;
            }
        }

        &:last-child {
            border: none;
        }
    }
}

.list-contact {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        position: relative;
        line-height: $lineHeight;
        padding: $padding-sm;
        border-bottom: 1px dashed $borderColor;

        span {
            display: block;
            font-weight: $fontBold;
        }
    }
}

// Editor Page

.editor-page {
    .image-upload {
        display: block;
        border: 2px dashed $borderColor;
        padding: $padding-xl;
        text-align: center;
        margin-bottom: $padding-xl;
        color: textColorLight;
        @include transition(all .2s);

        .icon {
            display: block;

            i {
                font-size: 2*$fontSizeLarge;
            }
        }

        p {
            font-size: $fontSizeSmall;
            line-height: 1.47;
            margin-bottom: 0;
            margin-top: $padding;
        }

        &:hover {
            border-color: $primaryColor;
            color: $primaryColor;
            background: $bodyColorLight;
            @include transition(all .2s);
        }
    }

    .file-upload {
        position: relative;
        display: block;
        text-align: center;
        margin-bottom: $padding-xl;
        color: textColorLight;
        margin-top: $padding;
        @include transition(all .2s);

        .image-title-wrap {
        padding: 0 15px 15px 15px;
        color: #222;
        }

        .image-upload-wrap {
            position: relative;
            border: 2px dashed $borderColor;
            padding: $padding-xl;

            &:hover {
                border: 2px dashed $primaryColor;
            }

            .drag-text {
                text-align: center;

                .icon {
                    display: block;
                    font-size: 2*$fontSizeLarge;
                    line-height: 1.2;
                }

                label {
                    display: block;
                    font-weight: 100;
                    text-transform: uppercase;
                    color: $textColor;
                    margin: 0;
                }
            }

            .file-upload-input {
                position: absolute;
                margin: 0;
                padding: 0;
                left: 0;
                top: 0;
                padding: $padding-xl;
                width: 100%;
                height: 100%;
                outline: none;
                opacity: 0;
                cursor: pointer;
            }

            &.image-dropping {
                background-color: $bodyColorLight;
                border: 2px dashed $primaryColor;
            }
        }

        .file-upload-content {
            display: none;
            padding: $padding-xl;
            border: 2px dashed $primaryColor;

            .file-upload-image {
                max-height: 200px;
                max-width: 200px;
                margin: auto;
                margin-bottom: $padding-sm;
            }
        }
    }
}

// Notification Page
.list-notification {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        position: relative;
        padding: $padding $padding $padding ($navSizeMobile + 2*$padding);
        border-bottom: 1px dashed $borderColor;

        .avatar {
            position: absolute;
            left: $padding;
            top: $padding;
            width: $navSizeMobile;
            height: $navSizeMobile;
            overflow: hidden;
            @include border-radius(50%);

            img {
                width: 100%;
            }
        }

        .time {
            color: $textColorLight;
            font-size: $fontSizeSmall;
            display: block;
        }
    }
}

.link-notification {
    margin: 0 (-$padding);
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        position: relative;

        a {
            display: block;
            padding: $padding;
            border-bottom: 1px dashed $borderColor;
            border-left: 2px solid $blueAlert;

            .time {
                color: $textColorLight;
                font-size: $fontSizeSmall;
                display: block;
            }

            &:hover {
                background: $bodyColorLight;
            }
        }

        &.read {
            a {
                font-weight: $fontNormal;
                border-left: none;
            }
        }

        &.active {
            a {
                background: $bodyColorLight;
                color: $primaryColor;
            }
        }

        &.list-button {
            border: none;

            a {
                background: $redAlert;
                text-align: center;
                color: white;
                border: none;
                @include box-shadow(0 1px 2px rgba(0,0,0,.1));

                i {
                    display: inline-block;
                    margin-right: $padding-sm;
                    //font-size: 1.15*$fontSize;
                }

                &:hover {
                    background: lighten($redAlert, 2%);
                    color: $white;
                    @include transform(translate(0, -3px));
                    @include box-shadow(0 3px 15px 0 rgba(0,0,0,0.2));
                    @include transition(all 200ms ease-in);
                }
            }
        }
    }
}

/** Stats **/
.summary-stats {
    text-align: right;

    .box {
        @include transition(all .25s ease);
        text-align: left;

        h2 {
            font-weight: $fontNormal;
            font-size: 1.5*$fontSize;
            color: $textColorDark;
            margin: 0;
            @include transition(all .25s ease);
        }

        h6 {
            margin: 0;
        }

        .change {
            right: $padding;
            font-size: 2.25*$fontSize;
            font-weight: $fontLight;
            @include vertical-align(absolute);
        }

        &:hover {
            h2 {
                //color: $primaryColor;
                @include transition(all .25s ease);
            }
        }
    }
}

.order-tabs {
    margin:  0 -($padding-sm);
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    li {
        flex: 0 0 25%;
        padding: $padding-sm;

        a {
            display: block;
            background: white;
            padding: $padding;
            position: relative;
            text-align: center;
            font-weight: $fontNormal;
            margin: $padding-sm 0;
            @include box-shadow(0 5px 15px rgba(0,0,0,.05));
            @include transition(all .25s ease);

            span {
                display: block;
                font-size: 2.5*$fontSize;
                font-weight: $fontLight;
                line-height: 1.3;
            }

            &:hover {
                color: $redAlert;
            }

            &.active {
                background: $redAlert;
                color: white;
            }
        }
    }
}

.action-tabs {
    margin:  0 -($padding-sm);
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    li {
        flex: 0 0 25%;
        padding: $padding-sm;

        a {
            display: block;
            background: white;
            padding: $padding-xl $padding;
            position: relative;
            text-align: center;
            font-weight: $fontNormal;
            margin: $padding-sm 0;
            @include box-shadow(0 5px 15px rgba(0,0,0,.05));
            @include transition(all .25s ease);

            span {
                display: block;
                font-size: 2.5*$fontSize;
                font-weight: $fontLight;
                line-height: 1.3;
            }

            &:hover {
                color: $redAlert;
            }

            &.active {
                background: $redAlert;
                color: white;
            }
        }
    }
}

.dot-graph {
    height: 300px;
}

.chart img{
    max-width: 100%;
}

// DataTables
.full-dataTables {
    width: 100%;
    color: $textColor;

    .row {
        width: 100%;
    }

    .dataTables_filter {
        text-align: right;

        input {
            //max-width: 300px;
            margin-left: $padding;
        }
    }

    table.dataTable {
        border-collapse: collapse !important;
        margin-top: $padding;
    }

    .dataTables_length select {
        margin: 0 $padding-xs;
    }

    .pagination {
        justify-content: flex-end;

        .paginate_button {
            a {
                position: relative;
                display: block;
                padding: .5rem .75rem;
                margin-left: -1px;
                line-height: 1.25;
                color: $textColor;
                background-color: white;
                border: 1px solid $borderColor;
            }

            &.active a {
                background: $bodyColor;
                cursor: default;
            }
        }
    }

    @media (min-width: $grid-sm) {
        .form-inline label {
            justify-content: inherit;
        }
    }
}

.summary-graph {
    margin: 0;
    height: 400px;
    padding: $padding;

    #sales-chart {
        height: 400px;
    }
}

// Morris Chart
.morris-hover {
    position: absolute;
    z-index: 5;

    &.morris-default-style {
        padding: 6px;
        color: #666;
        background: rgba(255,255,255,0.8);
        border: solid 1px rgba(230,230,230,0.8);
        font-size: $fontSize;
        text-align: left;
        @include border-radius(4px);

        .morris-hover-row-label {
            font-weight: bold;
            margin: 0.25em 0;
        }

        .morris-hover-point {
            white-space: nowrap;
            margin: 0.1em 0;
        }
    }
}

/** Payment **/
.payment-table {
    width: 100%;

    .payment-table-download {
        text-align: center;
    }
}

/** Social Media **/
.social-tabs {
    margin:  0 -($padding-sm);
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    li {
        flex: 0 0 25%;
        padding: $padding-sm;

        a {
            display: block;
            background: white;
            position: relative;
            text-align: left;
            font-weight: $fontNormal;
            margin: $padding-sm 0;
            padding: $padding;
            padding-left: 50px + $padding;
            @include box-shadow(0 5px 15px rgba(0,0,0,.05));
            @include transition(all .25s ease);

            i {
                display: block;
                font-size: 2.5*$fontSize;
                font-weight: $fontLight;
                line-height: 1.3;
                position: absolute;
                left: $padding;
                width: 50px;
                @include opacity(.65);
            }

            span {
                display: block;
            }

            &:hover {
                color: $redAlert;
            }

            &.active {
                background: $redAlert;
                color: white;

                i {
                    @include opacity(1);
                }
            }
        }
    }

    &.social-2 {
        li {
            flex: 0 0 50%;
        }
    }
}

.social-posts {
    .social-hidden {
        display: none;
    }

    .twitter-post {
        ul {
            list-style: none;
            padding: 0;
            margin: 0 (- $padding);

            li {
                position: relative;
                padding: $padding $padding;
                border-bottom: 1px dashed $borderColor;

                .user {
                    margin-bottom: $padding;

                    img {
                        display: inline-block;
                        margin-right: $padding-xs;
                        width: 34px;
                        height: 34px;
                        @include border-radius(50%);
                    }
                }

                .timePosted {
                    display: block;
                    font-weight: $fontNormal;
                    font-size: $fontSizeSmall;
                    font-style: italic;
                    color: $textColorLight;
                    float: left;
                    margin: 0;

                    a {
                        font-weight: $fontNormal;
                        border: none;
                        text-decoration: none;
                    }
                }

                .interact {
                    text-align: right;
                    font-size: $fontSizeSmall;
                    margin: 0;
                    @extend %clearfix;

                    a {
                        font-weight: $fontNormal;
                        border: none;
                        text-decoration: none;
                        display: inline-block;
                        margin-left: $padding-sm;

                        &:first-child {
                            margin: 0;
                        }
                    }
                }

                .media {
                    margin-top: $padding-sm;
                    max-height: 200px;
                    overflow: hidden;
                    text-align: center;
                    @include transition(.2s ease);

                    img {
                        max-width: 100%;
                    }
                }

                &:hover {
                    background: $bodyColorLight;

                    .media {
                        max-height: 500px;
                        overflow: auto;
                        @include transition(.2s ease);
                    }
                }
            }
        }
    }

    .facebook-post {
        ul {
            list-style: none;
            padding: 0;
            margin: 0 (- $padding);

            li {
                position: relative;
                padding: $padding $padding;
                border-bottom: 1px dashed $borderColor;

                .user {
                    margin-bottom: $padding;

                    img {
                        display: inline-block;
                        margin-right: $padding-xs;
                        width: 34px;
                        height: 34px;
                        @include border-radius(50%);
                    }
                }

                .timePosted {
                    display: block;
                    font-weight: $fontNormal;
                    font-size: $fontSizeSmall;
                    font-style: italic;
                    color: $textColorLight;
                    float: left;
                    margin: 0;

                    a {
                        font-weight: $fontNormal;
                        border: none;
                        text-decoration: none;
                    }
                }

                .interact {
                    text-align: right;
                    font-size: $fontSizeSmall;
                    margin: 0;
                    @extend %clearfix;

                    a {
                        font-weight: $fontNormal;
                        border: none;
                        text-decoration: none;
                        display: inline-block;
                        margin-left: $padding-sm;

                        &:first-child {
                            margin: 0;
                        }
                    }
                }

                .media {
                    margin-top: $padding-sm;
                    max-height: 200px;
                    overflow: hidden;
                    text-align: center;
                    @include transition(.2s ease);

                    img {
                        max-width: 100%;
                    }
                }

                &:hover {
                    background: $bodyColorLight;

                    .media {
                        max-height: 500px;
                        overflow: auto;
                        @include transition(.2s ease);
                    }
                }
            }
        }
    }

    .instagram-post {
        ul {
            list-style-type: none;
            padding-left: 0;
            margin-top: $padding-sm;

            li {
                margin-top: $padding-sm;
                margin-bottom: $padding-sm;

                a {
                    display: block;
                    position: relative;
                    overflow: hidden;
                    @include box-shadow($boxShadow);
                    @include transition(.3s all ease-in-out);
                    @include border-radius(4px);

                    img {
                        display: block;
                        max-width: 100%;
                    }

                    .overlay {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,0.5);
                        @include opacity(0);
                        @include transition(.3s all ease-in-out);

                        span {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            color: white;
                            @include transform(translateY(-50%));
                        }
                    }

                    &:hover {
                        @include transform(translate(0, -3px));
                        @include box-shadow(0 3px 15px 0 rgba(0,0,0,0.2));

                        .overlay {
                            @include opacity(1);
                        }
                    }
                }
            }
        }
    }
}

.social-add-post {

    .social-add-post-button {
        @include opacity(0);
        height: 0;
        overflow: hidden;
        @include transition(ease 0.3s all);

        input[type=submit] {
            color: white;
            display: inline-block;
            width: auto;
            padding: 0 $padding-lg;
            line-height: $inputSizeSm;
            height: $inputSizeSm;
            @include border-radius(4px);
        }

        .counter {
            display: inline-block;
            line-height: $inputSizeSm;
            background: $bodyColorLight;
            padding: 0 $padding-sm;
            @include border-radius(4px);
        }
    }

    textarea {
        width: 100%;
        border: 1px solid $borderColor;
        height: $navSizeMobile;
        padding: $padding-xs $padding;
        color: $textColor;

        &:focus, &:valid {
            height: 100px;
            outline: none;
            border-color: darken($borderColor, 50%);
            @include box-shadow(none);

            & ~ .social-add-post-button {
                height: auto;
                overflow: visible;
                @include opacity(1);
                @include transition(ease 0.3s all);
            }
        }
    }
}

/** Elements **/
.pop-notification-container {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    max-width: 500px;
    display: block;
    pointer-events: initial;
    padding: $padding-lg;

    .pop-notification {
        background: white;
        padding: $padding-lg;
        border: 1px solid #ececec;
        position: relative;
        @include border-radius(6px);
        @include box-shadow(0px 0px 25px 0px rgba(0, 0, 0, 0.04));

        .title {
            font-weight: $fontBold;
            color: $textColorDark;

            .close {
                color: $textColorLight;
                position: absolute;
                right: $padding;
                top: $padding;
            }
        }

        .content {
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/** Error **/
#error {
    min-height: calc(100vh - 110px);
    height: 80vh;

    .error-box {
        max-width: 600px;
        text-align: center;
        padding: 1rem;
        margin: 0 auto;
        @include vertical-align();

        .error-image {
            display: block;
            text-align: center;
            margin-bottom: 2rem;

            img {
                max-width: 100%;
            }
        }

        h1 {
            font-size: 2rem;
        }
    }
}

/** Footer **/
footer {
	display: block;
	padding: $padding-xl 0 $padding;
	color: $textColor;
	font-size: $fontSizeSmall;
	letter-spacing: 1px;
	text-transform: uppercase;

	ul {
		margin: 0;
		padding: 0;
		text-align: center;

		li {
			display: inline-block;
			list-style: none;

			a {
				display: block;
				color: $textColorLight;
				padding: .5*$padding $padding;

				&:hover {
					color: $primaryColor;
				}
			}
		}
	}
}

/** Responsive Helpers **/
@media screen and (max-width: ($grid-md - 1px )) {
    .mobile-align-left {
        text-align: left;
    }

    .mobile-align-right {
        text-align: right;
    }

    .mobile-align-center {
        text-align: center;
    }

    .full-dataTables, .table {
        overflow-x: scroll;
    }
}

$fontSize:        14px;
$fontSizeLarge:   18px;
$fontSizeSmall:   12px;
$fontPrimary:     'Poppins', 'Helvetica Neue', 'Lucida Grande', Arial, Verdana, sans-serif;
$fontHeader:      $fontPrimary;
$fontLight:       300;
$fontNormal:      400;
$fontBold:        600;
$fontStyle:       normal;
$letterSpacing:   0.015em;
$lineHeight:      1.77;

// Sizes
$sidebarSize:     330px;
$navSize:         80px;
$navSizeMobile:   50px;
$logoSize:        40px;
$logoSizeMobile:  36px;
$padding-xs:      5px;
$padding-sm:      10px;
$padding:         15px;
$padding-lg:      20px;
$padding-xl:      30px;
$boxHeaderSize:   54px;

$inputSizeLg:     60px;
$inputSize:       50px;
$inputSizeSm:     40px;
$inputSizeXs:     18px;

// Bootstrap Grid Breakpoints
$grid-xs:         0;
$grid-sm:         544px;
$grid-md:         768px;
$grid-lg:         992px;
$grid-xl:         1200px;

// Colors
$primaryColor:    #FF7B00;
$secondaryColor:  #FFB900;
$tirtiaryColor:   #4680FE;

$textColor:       #627B93;
$textColorLight:  #8CA0B3;
$headerColor:     darken($textColor,10%);
$textColorDark:   $headerColor;

$white:           #FFFFFF;
$bodyColor:       #EAF2F9;
$bodyColorDark:   #22313F;
$bodyColorLight:  lighten($bodyColor, 3%);
$borderColor:     rgba(140,160,179,0.3);
$overlayColor:    rgba(255,255,255,0.5);

$sidebarShadow:   #D9E4ED;
$boxShadow:       0 2px 2px 1px rgba(119,151,178,0.16);

// Forms Colors
$greenAlert:      #00bf86;
$redAlert:        #DD6777;
$blueAlert:       #00c0ef;
$yellowAlert:     #ffeb3b;
$ltorange:        lighten($secondaryColor, 20%);

// Social Media Brand Colors
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$soundcloud: #ff3a00;
$behance: #1769ff;

// Animation Speed
$animateFast: 0.2s;
$animateNormal: 0.3s;
$animateSlow: 0.6s;
$t: 0.3s;

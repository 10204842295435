/** Class Helpers **/
.or-separator {
    display: block;
    width: 70%;
    margin: $padding auto;
    position: relative;
    border-bottom: 2px dashed $borderColor;
    text-align: center;
    color: $textColorLight;

    span {
        position: relative;
        z-index: 2;
        background: white;
        padding: 0 $padding;
        bottom: -12px;
    }
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.bg-white {
    background: white;
}

.bg-grey {
    background: $bodyColorLight;
}

.bg-dark {
    background: $bodyColorDark;
}

.bg-red {
    background: $redAlert !important;
    color: white;
}

.bg-green {
    background: $greenAlert !important;
    color: white;
}

.bg-blue {
    background: $blueAlert !important;
    color: white;
}

.bg-alert-blue {
    background: #d9edf7 !important;
    color: #31708f;
    border: 1px solid #bcdff1;
}

.bg-yellow {
    background: $yellowAlert !important;
    color: $textColor;
}

.bg-alert-yellow {
    background: #fcf8e3 !important;
    color: #8a6d3b;
    border: 1px solid #faf2cc;
}

.bg-ltorange {
    background: $ltorange !important;
    color: $textColor;
}

.color-light {
    color: $textColorLight !important;
}

.color-red {
    color: $redAlert !important;
}

.color-green {
    color: $greenAlert !important;
}

.color-blue {
    color: $blueAlert !important;
}

.color-yellow {
    color: $yellowAlert !important;
}

.color-grey {
    color: $textColorLight !important;
}

.align-bottom {
    height: 100%;
    @include flexbox-col(bottom);
    align-self: center;
}

.align-center, .align-middle {
    @include flexbox-col(center);
}

.align-bottom, .aligh-center, .align-middle {
    height: 100%;

    img {
        -webkit-align-self: center;
        align-self: center;
    }
}

.btn {
    border-color: $borderColor;
}

.button {
    position: relative;
    text-transform: uppercase;
    border: none;
    color: $white;
    background: $primaryColor;
    display: inline-block;
    font-size: .96*$fontSize;
    font-weight: $fontNormal;
    margin: 0;
    padding: 0 $padding-lg;
    height: $inputSize;
    line-height: $inputSize;
    letter-spacing: 1px;
    cursor: pointer;
    vertical-align: middle;
    overflow: hidden;
    @include border-radius(3px);
    @include transition(all 200ms ease-in);
    @include box-shadow(0 1px 2px rgba(0,0,0,.1));

    i {
        display: inline-block;
        margin-right: $padding-sm;
        //font-size: 1.15*$fontSize;
    }

    &:hover {
        background: lighten($primaryColor, 2%);
        color: $white;
        @include transform(translate(0, -3px));
        @include box-shadow(0 3px 15px 0 rgba(0,0,0,0.2));
        @include transition(all 200ms ease-in);
    }

    &.button-disabled {
        background: $bodyColor;
        color: $textColor;
        cursor: not-allowed;
        @include box-shadow(none);

        &:hover {
            @include transform(translate(0));
            @include box-shadow(none);
        }
    }

    &.button-blue {
        background: $tirtiaryColor;
        color: white;

        &:hover {
            background: lighten($tirtiaryColor, 2%);
        }
    }

    &.button-green {
        background: $greenAlert;
        color: white;

        &:hover {
            background: darken($greenAlert, 5%);
        }
    }

    &.button-red {
        background: $redAlert;
        color: white;

        &:hover {
            background: darken($redAlert, 5%);
        }
    }

    &.button-facebook {
        background: $facebook;
        color: white;

        &:hover {
            background: lighten($facebook, 2%);
        }
    }

    &.button-has-icon, &.has-icon {
        padding: 0 $padding-lg 0 ($inputSize + $padding);

        i {
            display: block;
            background: rgba(0,0,0,0.1);
            width: $inputSize;
            height: $inputSize;
            line-height: $inputSize;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
        }
    }

    &.button-sm {
        height: $inputSizeSm;
        line-height: $inputSizeSm;

        &.button-has-icon, &.has-icon {
            padding: 0 $padding-lg 0 ($inputSizeSm + $padding);

            i {
                width: $inputSizeSm;
                height: $inputSizeSm;
                line-height: $inputSizeSm;
            }
        }
    }

    &.button-lg {
        height: $inputSizeLg;
        line-height: $inputSizeLg;

        &.button-has-icon, &.has-icon {
            padding: 0 $padding-lg 0 ($inputSizeLg + $padding);

            i {
                width: $inputSizeLg;
                height: $inputSizeLg;
                line-height: $inputSizeLg;
            }
        }
    }
}

.font-2x {
    font-size: 2*$fontSize;
}

.seperator {
    position: relative;
    display: block;
    height: 1px;
    width: 143px;
    margin: 2*$padding auto $padding;
    @include linear-gradient(to right,rgba(0,0,0,0) 13%,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0) 87%);
}

.short-hr {
    margin: 1.5*$padding 0;

    span {
        display: block;
        width: 10%;
        max-width: 100px;
        height: 1px;
        background-color: rgba(0,0,0,0.75);
    }
}

.image {
    display: inline-block;

    img {
        max-width: 100%;
    }

    &.image-small {
        width: 30px;
        height: 30px;
        overflow: hidden;
    }

    &.image-circle {
        @include border-radius(50%);
        overflow: hidden;
    }

    &.image-shadow-small {
        @include box-shadow(0 1px 2px $borderColor);
    }
}

.font-bold {
    font-weight: $fontBold;
}

.font-italic {
    font-style: italic;
}

.table {
    &.table-align-middle {
        td {
            vertical-align: middle;
        }
    }

    &.no-border {
        th, td {
            border: none;
        }
    }
}

/** Basic Setup **/
body, html {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    min-height: 100vh;
}

body {
    background: $bodyColor;
    color: $textColor;
    font-family: $fontPrimary;
    font-style: $fontStyle;
    font-weight: $fontNormal;
    font-size: $fontSize;
    letter-spacing: $letterSpacing;
    line-height: $lineHeight;
    @include font-smoothing(on);
    min-height: 100vh;
}

body, input, select, textarea {
    @include bezier-transition(all, $animateFast, cubic-bezier(0.42, 0, 0.58, 1));
}

a {
    @include bezier-transition(all, $animateFast, cubic-bezier(0.42, 0, 0.58, 1));
    cursor: pointer;
    color: $textColor;
    text-decoration: none;
    font-weight: $fontBold;
    outline: 0;

    &:hover {
        color: $primaryColor;
        border-color: $secondaryColor;
        text-decoration: none !important;
        outline: none !important;
        outline: 0 !important;
    }

    &:active, &:focus {
        outline: 0 !important;
        text-decoration: none;
        @include box-shadow(none);
        //color: $secondaryColor;
    }
}

strong, b, .strong, .bold {
    font-weight: $fontBold;
}

em, i {
    font-style: italic;
}

p {
    font-family: $fontPrimary;
    font-size: $fontSize;
    font-weight: $fontNormal;
    line-height: $lineHeight;

    a {
        border-bottom: 1px dotted $borderColor;
        line-height: $lineHeight;
    }

    &.leader {
        font-size: $fontSizeLarge;
    }

    &.center-leader {
        text-align: center;
        font-size: $fontSizeLarge;
        font-weight: $fontLight;

        strong {
            font-weight: $fontNormal;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $fontHeader;
	font-weight: $fontNormal;
	line-height: 1.47;
    margin: 0 0 $padding;
    @include font-smoothing(on);

    a {
        color: inherit;
        text-decoration: none;
    }

    small {
        color: inherit;
    }

    &.add-line {
        position: relative;
        padding-bottom: 1.5rem;

        &:after {
            display: block;
            position: absolute;
            content: " ";
            bottom: .5rem;
            height: 3px;
            width: 75px;
            background: $textColor;
            @include opacity(0.75);
        }

        &.secondary-line:after {
            background: $secondaryColor;
        }

        &.primary-line:after {
            background: $primaryColor;
        }
    }
}

h1 {
	font-weight: $fontLight;
    line-height: 1.4;
}

h5, h6 {
	font-family: $fontPrimary;
    font-weight: $fontBold;
}

h5 {
    font-size: $fontSize + 2px;
}

h6 {
    font-size: $fontSize;
    color: $textColorLight;
}

small {
    font-size: 85%;
}

figure {
    display: block;
    border: 1px solid $borderColor;
    padding: 0.75rem 0.75rem 0;
    text-align: center;
    margin-bottom: 2rem;

    img {
        max-width: 100%;
    }

    figcaption {
        display: block;
        padding: 0.25rem 0.75rem;
        font-size: 85%;
        font-style: italic;
        color: lighten($textColor, 10%);
    }
}

blockquote {
    background: $bodyColor;
    padding: $padding 2*$padding;
    border-left: 3px solid $primaryColor;
    font-size: 1.5*$fontSize;
}

pre {
    background: $bodyColor;
    padding: $padding 2*$padding;
    border-left: 3px solid $borderColor;
    font-size: $fontSize;
}
